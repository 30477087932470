@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Sans-Serif;
}

body {
  font-family: Arial, Sans-Serif;
  color: #002b3a;
}

@layer components {
  .btn {
    @apply flex justify-center py-2 px-4 rounded-md border border-transparent text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed transition;
  }

  .btn-default {
    @apply btn;
    @apply border-gray-200 bg-white text-gray-700 hover:bg-gray-50;
  }

  .btn-primary {
    @apply btn;
    @apply bg-primary-2 hover:bg-primary-3;
  }

  .btn-secondary {
    @apply btn;
    @apply bg-primary-1 hover:bg-primary-2;
  }

  .btn-outline-primary {
    @apply btn;
    @apply text-primary-2 hover:text-white hover:bg-primary-2 border-primary-2;
  }

  .toast {
    @apply rounded-none;
  }

  .toast-success {
    @apply bg-green-100 border-green-300 text-green-700;
  }

  .toast-info {
    @apply bg-blue-100 border-blue-300 text-blue-700;
  }

  .toast-warning {
    @apply bg-orange-100 border-orange-300 text-orange-700;
  }

  .toast-error {
    @apply bg-red-100 border-red-300 text-red-700;
  }
}

.app-sidebar {
  height: calc(100vh - 4rem);
}

.phone-input input {
  outline: 0;
}

.table-header-wrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}
